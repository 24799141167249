import React, { useRef } from 'react';
import { graphql, Link as GLink } from 'gatsby';

import {
  Heading, Box, Divider, OrderedList, ListItem, Text, Center, Button, Flex, Link, Stack,
} from '@chakra-ui/react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Content from '../../components/content';
import AsideBox from '../../components/AsideBox';

import { workAheadStyles } from '../../styles';
import ocean from '../../images/ocean.png';

const JobStabilityArticle = ({ data, location }) => {
  const content = {
    title: 'How to evaluate job stability',
    heading: 'How to evaluate job stability',
    intro: 'Go beyond basic supply and demand to learn the indicators you should look at to maintain gainful employment until you are ready to retire.',
    subheading: 'Job Stability tells us if a field is growing, or shrinking',
  };

  const layoutRef = useRef();

  return (
    <Layout title={content.title} description={content.intro} ref={layoutRef} image={ocean} location={location}>
      <Heading as="h1" size="3xl" fontWeight="100" mb="4">{content.heading}</Heading>
      <Flex dir="row" mb="8">
        <Text fontSize="xl">{content.intro}</Text>
      </Flex>
      <Box
        py="8"
        sx={{ ...workAheadStyles.fullWidthBackground }}
        height={{ base: 100, md: 200 }}
        bgImage={`url(${ocean})`}
        bgPosition="center left"
        bgSize="cover"
        bgRepeat="no-repeat"
      />
      <Box bg="whiteAlpha.600" p={{ base: 8, md: 16 }} position="relative">
        <Text
          fontSize="sm"
          position="absolute"
          top="0"
          right="0"
          mt={{ base: 2, md: 8 }}
          mr={{ base: 2, md: 8 }}
        >Posted June 21, 2021
        </Text>
        <Box maxW="2xl" mb="8">
          <Heading size="lg" mb="4">{content.subheading}</Heading>
          <Text>
            <OrderedList>
              <ListItem> <Link href="#employment-projections">Employment Projections - Am I in a growing field?</Link></ListItem>
              <ListItem> <Link href="#job-density">Job Density - Can I get a job where I live?</Link></ListItem>
              <ListItem> <Link href="#risk-of-automation">Risk of Automation - Is a machine going to take my job?</Link></ListItem>
              <ListItem> <Link href="#taking-a-holistic-view">Taking a holistic view</Link></ListItem>
            </OrderedList>
          </Text>
        </Box>

        <Divider mb="8" />

        <Box mb="8" maxW="xl">
          <Text>Like any big decision, deciding on a profession, or a new job, is about balancing the long view with getting the details right from the very beginning. Understanding the factors that influence long term stability can help you know what to look for when job hunting, inform what major you choose to study in college, or help you evaluate if you should make a career change. At Work ahead, we think about job stability based on three factors: Employment Projections, Job Density, and Risk of Automation.</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <Heading as="h2" size="md">Employment Projections - Am I in a growing field?</Heading>
          <a id="employment-projections" />
          <Text>Employment Projections gives us information on how experts think the number of people employed in a job will grow or shrink over a period of 10 years. The US Bureau of Labor Statistics most recent reports from 2019 told us that average job growth would be 14% by the year 2029. That means 6 million new jobs will be added, the majority of which will be in healthcare. This isn't to say that all occupations will grow at the same rate. If you work as a word processor or typist, you will see a drop of 36% in the number of jobs by 2029.</Text>
          <Text>Understanding where your current or future job sits will help you better prepare and avoid potential potholes in the road ahead.</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <Heading as="h2" size="md">Job Density - Can I get a job where I live?</Heading>
          <a id="job-density" />
          <Text>Job Density refers to the number of jobs in an area compared to the national average. Lots of factors go into why some places have greater numbers of an occupation than others. If you are a motor boat operator, you are 31 times more likely to find a job in Hawaii. If you specialize in working underground mining machines, you are 18 times more likely to find a job in West Virginia. Job Density, referred to as Location Quotient by the BLS, helps us compare region to region by normalizing the numbers. A density of 1 is average. If you have 10 dentists in your town and the national average of dentists in every town is 10, you get a Job Density of 1.</Text>
          <Text>If you are young and trying to decide where to settle down, this can help you pick a location to move to where you have the greatest likelihood of finding the job of your dreams. if you are an established professional, this can help you understand how likely you are to find a similar job in your location, versus having to move somewhere with a higher Job Density.</Text>
        </Box>
        { false && (
        <Box mb="10" py="16" sx={{ ...workAheadStyles.fullWidthBackground }} bg="white">
          <Heading as="h3" size="sm" mb="4">Is your profession stable enough to get you to retirement?</Heading>
          <Flex>
            <Box maxWidth="50%">
              <Text>We pulled together the factors that influence future job prospects to give you a holistic view of the risks you may face along the path to retirement.</Text>
            </Box>
            <Center flex="1">
              <Button as={GLink} to="/job-stability/calculator" variant="outline" colorScheme="black" size="lg">Start planning</Button>
            </Center>
          </Flex>
        </Box>
        )}
        <Box mb="8" maxW="xl">
          <a id="risk-of-automation" />
          <Heading as="h3" size="md">Risk of Automation - Is a machine going to take my job?</Heading>
          <Text>Automation has been a popular topic over the last few years when it comes to employment. Self driving cars, automated factories, self service restaurants have led to a lot of attention being given to the subject. What jobs are the most at risk?</Text>
          <Text>We think about Risk of Automation on a scale from 1-10. 1 is low risk, and will continue to be done by people. 10 means that the job has a high likelihood of being automated in the future. These metrics are based on research done by McKinsey Global Institute. They looked at industry trends of percent of time workers spent on common activities. For example: loading trucks vs manually entering data into computers. These percentages were compared with places where technology had already been proven in completing the same common activities.</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <a id="taking-a-holistic-view" />
          <Heading as="h3" size="md">Taking a holistic view</Heading>
          <Text>It's important to understand your profession's Job Stability, no matter where you are in your career journey. Taking a holistic view of these 3 factors along with your own goals can help you make the right processional moves.</Text>
          <Text>Check out our Job Stability calculator to get a handle on how if you are in a growing market, or if you need to make some changes.</Text>
          <Button as={GLink} to="job-stability/calculator" colorScheme="orange" mt="8">Check your job stability</Button>
        </Box>
      </Box>

      <Text fontSize="sm" float="right" mt="8">
        Cover photo by <Link to="https://unsplash.com/@eberhardgross" isExternal>Jeremy Bishop on Unsplash</Link>
      </Text>

    </Layout>
  );
};

export default JobStabilityArticle;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
